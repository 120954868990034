"use client";

import type React from "react";
import { useEffect, useRef } from "react";
import {
    useCountry,
    useInitialConfig,
    useIsWebview,
    usePrebidAB,
} from "../../../../components/ApplicationState/hooks";
import { useConsent } from "../../../../components/Consent/hooks";
import { pushDataLayer } from "../../../../helpers/dataLayer";
import type { DataLayerItemPageTrack } from "../../../../helpers/types";

type Props = {
    children: React.ReactNode;
};

const Template: React.FC<Props> = ({ children }) => {
    const isPageTracked = useRef(false);
    const country = useCountry();
    const consent = useConsent();
    const isWebview = useIsWebview();
    const webviewInitialConfig = useInitialConfig();
    const mobileWebType =
        isWebview !== null && isWebview
            ? (webviewInitialConfig?.mobileWebType ?? "web-app-react/unknown")
            : "web-app-react";
    const prebid = usePrebidAB();

    useEffect(() => {
        if (!isPageTracked.current && consent && prebid !== null) {
            const dataLayerItem: DataLayerItemPageTrack = {
                type: "pageTrack",
                event: "pagetrack",
                mobileWebType,
                // don't use usePathname, as it might rerender
                pageName: window.location.pathname,
                pageType: "Skigebiete",
                pageTypeDetail: "main",
                pageCountry: country,
                tcontent: undefined,
                tcdist: undefined,
                pageRegion: undefined,
                pageCity: undefined,
                pageId: undefined,
                overlay: false,
                overlayType: "ski_filter",
                ab_5497_a: prebid.ab_5497_a ?? undefined,
                ab_5497: prebid.ab_5497 ?? undefined,
            };

            const testGroup = window.sessionStorage.getItem("wr5232");
            if (testGroup) {
                dataLayerItem.ab_5232 = testGroup;
            }

            pushDataLayer(dataLayerItem);
            isPageTracked.current = true;
        }
    }, [country, mobileWebType, consent, prebid]);

    return children;
};

export default Template;
